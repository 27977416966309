import React, { useState, useEffect } from "react"
import { useForm, FormProvider, useFieldArray } from "react-hook-form"
import { Form, Button, Row, Col, Alert } from "react-bootstrap"
import StateList from "./StateList"
import CityList from "./CityList"
import PlusSymbol from "./plus-symbol.svg"
import MinusSymbol from "./minus-symbol.svg"
import ModifyForm from "./ModifyForm"

export default function ContactForm({ travelAssistanceData, toggleSuccessMessage }) {

  const [selectedPersons, setSelectedPersons] = useState([]);

  //Checkbox change handler - check first and last names to ensure the values are unique
  const handleCheckboxChange = (person) => {
    if (selectedPersons.some(p => p.first_name === person.first_name && p.last_name === person.last_name)) {
      setSelectedPersons(selectedPersons.filter(p => p.first_name !== person.first_name || p.last_name !== person.last_name));
    } else {
      setSelectedPersons([...selectedPersons, person]);
    }
  };



  const {
    register,
    formState: { errors },
    reset,
    //errors,
    watch,
    handleSubmit,
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      persons: []
    }
  })

  const watchObject = watch()

  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState("")
  const [disableSubmit, setDisableSubmit] = useState(false)

  const Required = () => <span style={styles.formError}>*</span>

  const styles = {
    formError: {
      color: "red",
      fontSize: "0.8rem",
      fontWeight: 700,
    },
  }
  console.log('watchObject', watchObject)
  const handleChange = async event => {
    setError(event.error ? event.error.message : "")
    console.log(watchObject)
  }

  const processFormData = data => {
    // Clone the data object to avoid mutating the original
    const processedData = { ...data }

    // Check if person is an array
    if (Array.isArray(processedData.person)) {
      // Iterate over each person object
      processedData.person = processedData.person.map(person => {
        // Check if veteran_service_org is an array
        if (Array.isArray(person.veteran_service_org)) {
          // Convert the array to a comma-delimited string
          person.veteran_service_org = person.veteran_service_org.join(", ")
        }
        return person
      })
    }

    return processedData
  }

  const submitForm = data => {
    // Process the form data
    //console.log(travelAssistanceData);
    const processedData = {
      ...processFormData(data),
      requester_first_name: travelAssistanceData.requester_first_name,
      requester_last_name: travelAssistanceData.requester_last_name,
      requester_email: travelAssistanceData.requester_email,
      requester_phone: travelAssistanceData.requester_phone
    };

    setProcessing(true)
    setDisableSubmit(true)
    //console.log('processedData', processedData)
    fetch(
      `https://script.google.com/macros/s/AKfycbxqnNaCbp_dgCeozRjgCOQq-PwbrHDWic1-foZ7lbbzWQmvDjGT6TJ32utnkt_Umx8S/exec`,
      {
        method: "POST",
        body: JSON.stringify(processedData),
      }
    )
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.text()
      })
      
      .then(result => {
        console.log(result);
        fetch('/.netlify/functions/mailgun-travel-request', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(processedData),
      })
      .then(res => {
        console.log('mailgun response', res);
        if (res.status === 200) {
          console.log('Data submitted to Mailgun');
          setSucceeded(true);
          reset();
        } else {
            console.log('Error submiting to Mailgun')
        }
    }).catch((error) => {
      setSucceeded(false);
      setDisableSubmit(false);
      console.log('Mailgun error!', error.message);
      alert("Error: " + error.message)
    })
      })
      .catch(error => {
        setSucceeded(false)
        setDisableSubmit(false)
        console.error("Error:", error)
        alert("Error: " + error.message)
      })
      
    //   fetch('/.netlify/functions/mailgun-christening-request', {
    //       method: 'POST',
    //       headers: {
    //           'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(processedData),
    //   })
    //   .then(res => {
    //     console.log('mailgun response', res);
    //     if (res.status === 200) {
    //       console.log('Data submitted to Mailgun');
    //       setSucceeded(true);
    //       reset();
    //       toggleSuccessMessage(false);
    //     } else {
    //         console.log('Error submiting to Mailgun')
    //     }
    // }).catch((error) => {
    //   setSucceeded(false);
    //   setDisableSubmit(false);
    //   console.log('Mailgun error!', error.message);
    //   alert("Error: " + error.message)
    // })
  }

  const SubmitRequestSuccessMessage = () => (
    <div className="submit-success-message text-center">
      <Alert variant="success">
        <h3>Thank you for your submission.</h3>
        <p>A copy of your request has been emailed to you.</p>
        <p>A representative will be in touch shortly.</p>
        <p>Invites and RSVP will ONLY be sent to the Requester.</p>
        <p>
          Tickets to the Christening of USS IDAHO SSN 799 are controlled by and
          at the discretion of the Electric Boat Shipyard. The USS IDAHO
          Commissioning Committee will do everything it can do to advocate on
          your behalf but we cannot make any guarantees, implied or otherwise,
          as to whether you will be offered and/or receive a ticket or the
          number of tickets you requested from Electric Boat.
        </p>
        <p style={{fontSize: 'larger'}}><strong>Please complete form below to request travel assistance from Uniglobe Travel.</strong></p>
      </Alert>
    </div>
  )
  
  const SubmitSuccessMessage = () => (
    <div className="submit-success-message text-center">
      <Alert variant="success">
        <h3>Thank you for your submission.</h3>
        <p>A copy of your request has been emailed to you.</p>
        <p>A representative of Uniglobe Travel will be in touch shortly.</p>
      </Alert>
    </div>
  )

  console.log('selectedPersons', selectedPersons)
  return (
    <>
      {succeeded ? (
        <SubmitSuccessMessage />
      ) : (
        <>
        <SubmitRequestSuccessMessage />
        <h4>Travel Assistance Request from Uniglobe Travel</h4>
          <form
            onSubmit={handleSubmit(submitForm)}
            id="travel-request-form"
            name="travel-request-form"
            style={{fontSize: '1.125rem'}}
          >
            <Row>
              <Col>
              <div className="fade-in">
                  <Row>
                    <Col>
                      <Alert variant="warning">
                        <p style={{marginBottom: 0, fontWeight: 'bold', fontSize: '1.125rem'}}>
                          Uniglobe travel is NOT part of the USS IDAHO
                          Commissioning Committee. The USS IDAHO
                          Commissioning Committee does NOT guarantee the
                          services provided by UNIGLOBE and only offers
                          this information portal as a convience.
                        </p>
                      </Alert>
                    </Col>
                  </Row>

                  <Form.Control
                    type="hidden"
                    {...register("timestamp")}
                    value={travelAssistanceData.timestamp}
                  />

                  <Row>
                    <Col>
                      <Form.Group>
                        <fieldset>
                          <input
                            className="radio"
                            type="checkbox"
                            inline
                            label="Yes"
                            id={`uniglobe_acknowledge_yes`}
                            name={`uniglobe_acknowledge_yes`}
                            value="Yes"
                            {...register(
                              `uniglobe_acknowledge`
                            )}
                          />
                          <label
                            className="radioLabel"
                            htmlFor={`uniglobe_acknowledge_yes`}
                          >
                            I acknowlege and agree to the statement
                            above.
                          </label>
                        </fieldset>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            
            {watchObject.uniglobe_acknowledge === "Yes" && (
              <div style={{
                marginBottom: "2rem",
                border: "1px solid lightgray",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}>
                <Row>
                  <Col>
                  <Form.Label><strong>Please select which persons for whom you're requesting travel assistance:</strong></Form.Label>
                  <Form.Group>
                    <fieldset>
                    {travelAssistanceData.person.map((item, index) => {
                      console.log('item', item);
                      return (
                        <div key={index}>
                          <input
                            className="radio"
                            type="checkbox"
                            value={item.first_name}
                            name={item.first_name}
                            id={`item.${index}first_name`}
                           //checked={selectedPersons.some(p => `${p.first_name}-${p.last_name}` === `${item.first_name}-${item.last_name}`)}
                            onChange={() => handleCheckboxChange(item)}
                          />
                          <label className="radioLabel" htmlFor={`item.${index}first_name`}>
                            {`${item.first_name} ${item.last_name}`}
                          </label>
                        </div>
                      ) 
                    })}
                    </fieldset>
                  </Form.Group>
                  </Col>
                </Row>
              </div>
            )}

            {selectedPersons.map((person, index) => (
              <>
            <div key={index} style={{
                marginBottom: "2rem",
                border: "1px solid lightgray",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}>
                <div>
                  <h4>Uniglobe Travel Assistance for {person.first_name} {person.last_name}</h4>
                </div>

               <Row class="fade-in">
               <Col sm={12} md={6}>
                 <Form.Group>
                   <Form.Label className="question-label">
                     First Name
                     <Required />
                   </Form.Label>
                     <Form.Control
                       className="formField"
                       type="text"
                       value={person.first_name}
                       readOnly
                       {...register(`persons[${index}].first_name`)}
                     />
                 </Form.Group>
               </Col>
               <Col sm={12} md={6}>
                 <Form.Group>
                   <Form.Label>
                     Last Name
                     <Required />
                   </Form.Label>
                     <Form.Control
                       className="formField"
                       type="text"
                       value={person.last_name}
                       readOnly
                       {...register(`persons[${index}].last_name`)}
                     />
                 </Form.Group>
               </Col>
             </Row>
  
             <Row>
               <Col sm={12} md={6}>
                 <Form.Group>
                   <Form.Label>Email Address</Form.Label>
                     <Form.Control
                       className="formField"
                       type="text"
                       value={person.email}
                       readOnly
                       {...register(`persons[${index}].email`, {
                         pattern: {
                           value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                           message: "Enter a valid e-mail address",
                         },
                       })}
                     />
                 </Form.Group>
               </Col>
               <Col sm={12} md={6}>
                 <Form.Group>
                   <Form.Label>
                     Phone Number (mobile preferred)
                     <Required />
                   </Form.Label>
                     <Form.Control
                       className="formField"
                       type="text"
                       value={person.phone}
                       readOnly
                       {...register(`persons[${index}].phone`, {
                         pattern: {
                           value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                           message: "Please enter a valid phone number",
                         },
                       })}
                     />
                 </Form.Group>
               </Col>
             </Row>
            
            <div className="fade-in">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Do you request an Airline Quote from
                    Uniglobe Travel?
                  </Form.Label>
                  <fieldset>
                    <input
                      className="radio"
                      type="radio"
                      label="Yes"
                      id={`persons[${index}].airline_quote_yes`}
                      name={`persons[${index}].airline_quote_yes`}
                      value="Yes"
                      {...register(
                        `persons[${index}].airline_quote`
                      )}
                    />
                    <label
                      className="radioLabel"
                      htmlFor={`persons[${index}].airline_quote_yes`}
                    >
                      Yes
                    </label>
                    <input
                      className="radio"
                      type="radio"
                      id={`persons[${index}].airline_quote_no`}
                      name={`persons[${index}].airline_quote_no`}
                      value="No"
                      {...register(
                        `persons[${index}].airline_quote`
                      )}
                      style={{ marginLeft: "2rem" }}
                    />
                    <label
                      className="radioLabel"
                      htmlFor={`persons[${index}].airline_quote_no`}
                    >
                      No
                    </label>
                  </fieldset>
                </Form.Group>
              </Col>
            </Row>

            {watchObject.persons[index].airline_quote ===
                "Yes" && (
                <div
                  className="fade-in"
                  style={{ marginBottom: "2rem" }}
                >
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Preferred arrival date in Groton,
                          CT area
                        </Form.Label>
                        <Form.Row>
                          <Col sm={3}>
                            <Form.Control
                              as="select"
                              id="airline_arrival_date"
                              {...register(
                                `persons.[${index}].airline_arrival_date`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="MON 11 MAR">
                              MON 11 MAR
                              </option>
                              <option value="TUE 12 MAR">
                              TUE 12 MAR
                              </option>
                              <option value="WED 13 MAR">
                              WED 13 MAR
                              </option>
                              <option value="THU 14 MAR">
                              THU 14 MAR
                              </option>
                              <option value="FRI 15 MAR">
                                FRI 15 MAR
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Preferred departure airport
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              as="select"
                              id="preferred_departure_airport"
                              {...register(
                                `persons.[${index}].preferred_departure_airport`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="BOI">
                                BOI - Boise
                              </option>
                              <option value="GEG">
                                GEG - Spokane
                              </option>
                              <option value="IDA">
                                IDA - Idaho Falls
                              </option>
                              <option value="PIH">
                                PIH - Pocatello
                              </option>
                              <option value="SLC">
                                SLC - Salt Lake City
                              </option>
                              <option value="LWS">
                                LWS - Lewiston
                              </option>
                              <option value="TWF">
                                TWF - Twin Falls
                              </option>
                              <option value="SUN">
                                SUN - Hailey / Sun Valley
                              </option>
                              <option value="OTHER">
                                OTHER (Specify)
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                    {watchObject.persons[index].departure_airport === "OTHER" && (
                        <Col className="fade-in">
                          <Form.Group>
                            <Form.Label>
                              Other preferred airport
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`persons.[${index}].other_preferred_airport`}
                              {...register(
                                `persons.[${index}].other_preferred_airport`
                              )}
                            />
                          </Form.Group>
                        </Col>
                      )}
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Secondary departure airport
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              as="select"
                              id="arrival_airport"
                              {...register(
                                `persons.[${index}].secondary_departure_airport`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="BOI">
                                BOI - Boise
                              </option>
                              <option value="GEG">
                                GEG - Spokane
                              </option>
                              <option value="IDA">
                                IDA - Idaho Falls
                              </option>
                              <option value="PIH">
                                PIH - Pocatello
                              </option>
                              <option value="SLC">
                                SLC - Salt Lake City
                              </option>
                              <option value="LWS">
                                LWS - Lewiston
                              </option>
                              <option value="TWF">
                                TWF - Twin Falls
                              </option>
                              <option value="SUN">
                                SUN - Hailey / Sun Valley
                              </option>
                              <option value="OTHER">
                                OTHER (Specify)
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                    {watchObject.persons[index].secondary_departure_airport ===
                        "OTHER" && (
                        <Col className="fade-in">
                          <Form.Group>
                            <Form.Label>
                              Other secondary airport
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`persons.[${index}].other_secondary_airport`}
                              {...register(
                                `persons.[${index}].other_secondary_airport`
                              )}
                            />
                          </Form.Group>
                        </Col>
                      )}
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Preferred arrival airport
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              as="select"
                              id="preferred_arrival_airport"
                              {...register(
                                `persons.[${index}].preferred_arrival_airport`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="PVD">
                                PVD - Providence Rhode
                                Island T.F. Green
                                International Airport
                              </option>
                              <option value="BDL">
                                BDL - Hartford Connecticut
                                Bradley International
                                Airport
                              </option>
                              <option value="OTHER">
                                OTHER (Specify)
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                    {watchObject.persons[index].preferred_arrival_airport ===
                        "OTHER" && (
                        <Col className="fade-in">
                          <Form.Group>
                            <Form.Label>
                              Preferred arrival airport
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`persons.[${index}].other_preferred_arrival_airport`}
                              {...register(
                                `persons.[${index}].other_preferred_arrival_airport`
                              )}
                            />
                          </Form.Group>
                        </Col>
                      )}
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Secondary arrival airport
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              as="select"
                              id="secondary_arrival_airport"
                              {...register(
                                `persons.[${index}].secondary_arrival_airport`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="PVD">
                                PVD - Providence Rhode
                                Island T.F. Green
                                International Airport
                              </option>
                              <option value="BDL">
                                BDL - Hartford Connecticut
                                Bradley International
                                Airport
                              </option>
                              <option value="OTHER">
                                OTHER (Specify)
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                    {watchObject.persons[index].secondary_arrival_airport ===
                        "OTHER" && (
                        <Col className="fade-in">
                          <Form.Group>
                            <Form.Label>
                              Other secondary arrival
                              airport
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`persons.[${index}].other_secondary_arrival_airport`}
                              {...register(
                                `persons.[${index}].other_secondary_arrival_airport`
                              )}
                            />
                          </Form.Group>
                        </Col>
                      )}
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Return trip - preferred arrival
                          airport
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              as="select"
                              id="return_preferred_arrival_airport"
                              {...register(
                                `persons.[${index}].return_preferred_arrival_airport`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="BOI">
                                BOI - Boise
                              </option>
                              <option value="GEG">
                                GEG - Spokane
                              </option>
                              <option value="IDA">
                                IDA - Idaho Falls
                              </option>
                              <option value="PIH">
                                PIH - Pocatello
                              </option>
                              <option value="SLC">
                                SLC - Salt Lake City
                              </option>
                              <option value="LWS">
                                LWS - Lewiston
                              </option>
                              <option value="TWF">
                                TWF - Twin Falls
                              </option>
                              <option value="SUN">
                                SUN - Hailey / Sun Valley
                              </option>
                              <option value="OTHER">
                                OTHER (Specify)
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                    {watchObject.persons[index].return_preferred_arrival_airport ===
                        "OTHER" && (
                        <Col className="fade-in">
                          <Form.Group>
                            <Form.Label>
                              Other preferred return arrival
                              airport
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`persons.[${index}].other_preferred_return_arrival_airport`}
                              {...register(
                                `persons.[${index}].other_preferred_return_arrival_airport`
                              )}
                            />
                          </Form.Group>
                        </Col>
                      )}
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Return trip - secondary arrival
                          airport
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              as="select"
                              id="return_secondary_departure_airport"
                              {...register(
                                `persons.[${index}].return_secondary_departure_airport`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="BOI">
                                BOI - Boise
                              </option>
                              <option value="GEG">
                                GEG - Spokane
                              </option>
                              <option value="IDA">
                                IDA - Idaho Falls
                              </option>
                              <option value="PIH">
                                PIH - Pocatello
                              </option>
                              <option value="SLC">
                                SLC - Salt Lake City
                              </option>
                              <option value="LWS">
                                LWS - Lewiston
                              </option>
                              <option value="TWF">
                                TWF - Twin Falls
                              </option>
                              <option value="SUN">
                                SUN - Hailey / Sun Valley
                              </option>
                              <option value="OTHER">
                                OTHER (Specify)
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                    {watchObject.persons[index].return_secondary_departure_airport ===
                        "OTHER" && (
                        <Col className="fade-in">
                          <Form.Group>
                            <Form.Label>
                              Other secondary airport
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`persons.[${index}].other_secondary_return_airport`}
                              {...register(
                                `persons.[${index}].other_secondary_return_airport`
                              )}
                            />
                          </Form.Group>
                        </Col>
                      )}
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Do you have TSA Precheck?
                        </Form.Label>
                        <fieldset>
                          <input
                            className="radio"
                            type="radio"
                            label="Yes"
                            id={`tsa_precheck_yes`}
                            value="Yes"
                            {...register(
                              `persons.[${index}].tsa_precheck`
                            )}
                          />
                          <label
                            className="radioLabel"
                            htmlFor={`tsa_precheck_yes`}
                          >
                            Yes
                          </label>
                          <input
                            className="radio"
                            type="radio"
                            id={`tsa_precheck_no`}
                            value="No"
                            {...register(
                              `persons.[${index}].tsa_precheck`
                            )}
                            style={{ marginLeft: "2rem" }}
                          />
                          <label
                            className="radioLabel"
                            htmlFor={`tsa_precheck_no`}
                          >
                            No
                          </label>
                        </fieldset>
                      </Form.Group>
                    </Col>
                    {watchObject.persons[index].tsa_precheck === "Yes" && (
                        <Col>
                          <Form.Group>
                            <Form.Label>
                              Known Traveler Number
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`persons.[${index}].known_traveler_number`}
                              {...register(
                                `persons.[${index}].known_traveler_number`
                              )}
                            />
                          </Form.Group>
                        </Col>
                      )}
                  </Row>

                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>
                          Airline frequent flyer name 1
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              as="select"
                              name={`persons.[${index}].airline_1`}
                              {...register(
                                `persons.[${index}].airline_1`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="United">
                                United
                              </option>
                              <option value="Delta">
                                Delta
                              </option>
                              <option value="American">
                                American
                              </option>
                              <option value="Alaska">
                                Alaska
                              </option>
                              <option value="Southwest">
                                Southwest
                              </option>
                              <option value="Jet Blue">
                                Jet Blue
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>
                          Airline frequent flyer number 1
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name={`persons.[${index}].airline_1_number`}
                          {...register(
                            `persons.[${index}].airline_1_number`
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>
                          Airline frequent flyer name 2
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              as="select"
                              name={`persons.[${index}].airline_2`}
                              {...register(
                                `persons.[${index}].airline_2`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="United">
                                United
                              </option>
                              <option value="Delta">
                                Delta
                              </option>
                              <option value="American">
                                American
                              </option>
                              <option value="Alaska">
                                Alaska
                              </option>
                              <option value="Southwest">
                                Southwest
                              </option>
                              <option value="Jet Blue">
                                Jet Blue
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>
                          Airline frequent flyer number 2
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name={`persons.[${index}].airline_2_number`}
                          {...register(
                            `persons.[${index}].airline_2_number`
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>
                          Airline frequent flyer name 3
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              as="select"
                              name={`persons.[${index}].airline_3`}
                              {...register(
                                `persons.[${index}].airline_3`
                              )}
                            >
                              <option value="">
                                Select
                              </option>
                              <option value="United">
                                United
                              </option>
                              <option value="Delta">
                                Delta
                              </option>
                              <option value="American">
                                American
                              </option>
                              <option value="Alaska">
                                Alaska
                              </option>
                              <option value="Southwest">
                                Southwest
                              </option>
                              <option value="Jet Blue">
                                Jet Blue
                              </option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>
                          Airline frequent flyer number 3
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name={`persons.[${index}].airline_3_number`}
                          {...register(
                            `persons.[${index}].airline_3_number`
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              )}

            <div>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Do you request a HOTEL Quote from
                      Uniglobe Travel?
                    </Form.Label>
                    <fieldset>
                      <input
                        className="radio"
                        type="radio"
                        label="Yes"
                        id={`persons.[${index}].hotel_quote_yes`}
                        name={`persons.[${index}].hotel_quote_yes`}
                        value="Yes"
                        {...register(
                          `persons.[${index}].hotel_quote`
                        )}
                      />
                      <label
                        className="radioLabel"
                        htmlFor={`persons.[${index}].hotel_quote_yes`}
                      >
                        Yes
                      </label>
                      <input
                        className="radio"
                        type="radio"
                        id={`persons.[${index}].hotel_quote_no`}
                        name={`persons.[${index}].hotel_quote_no`}
                        value="No"
                        {...register(
                          `persons.[${index}].hotel_quote`
                        )}
                        style={{ marginLeft: "2rem" }}
                      />
                      <label
                        className="radioLabel"
                        htmlFor={`persons.[${index}].hotel_quote_no`}
                      >
                        No
                      </label>
                    </fieldset>
                  </Form.Group>
                </Col>
              </Row>

              {watchObject.persons[index].hotel_quote ===
                  "Yes" && (
                  <div className="fade-in">
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            How many hotel rooms do you
                            require?
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                id="how_many_rooms"
                                {...register(
                                  `persons.[${index}].how_many_rooms`
                                )}
                              >
                                <option value="">
                                  Select
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">
                                  10
                                </option>
                                <option value="11">
                                  11
                                </option>
                                <option value="12">
                                  12
                                </option>
                                <option value="OTHER">
                                  OTHER (Specify)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                      <Col>
                        {watchObject.persons[index]
                            .how_many_rooms === "OTHER" && (
                            <Col className="fade-in">
                              <Form.Group>
                                <Form.Label>
                                  Other number of rooms
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name={`persons.[${index}].other_number_of_rooms`}
                                  {...register(
                                    `persons.[${index}].other_number_of_rooms`
                                  )}
                                />
                              </Form.Group>
                            </Col>
                          )}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Preferred hotel
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                id="preferred_hotel"
                                {...register(
                                  `persons.[${index}].preferred_hotel`
                                )}
                              >
                                <option value="">
                                  Select
                                </option>
                                <option value="Mystic Marriott Hotel and Spa">
                                  Mystic Marriott Hotel and
                                  Spa
                                </option>
                                <option value="Hilton Mystic">
                                  Hilton Mystic
                                </option>
                                <option value="Hampton Inn and Suites Mystic">
                                  Hampton Inn and Suites
                                  Mystic
                                </option>
                                <option value="Hyatt Place Mystic">
                                  Hyatt Place Mystic
                                </option>
                                <option value="Residence Inn by Marriott Mystic Groton">
                                  Residence Inn by Marriott
                                  Mystic Groton
                                </option>
                                <option value="Hampton Inn Groton">
                                  Hampton Inn Groton
                                </option>
                                <option value="The Whalers Inn">
                                  The Whalers Inn
                                </option>
                                <option value="Saybrook Point Resort & Marina">
                                  Saybrook Point Resort &
                                  Marina
                                </option>
                                <option value="House of 1833 Bed and Breakfast">
                                  House of 1833 Bed and
                                  Breakfast
                                </option>
                                <option value="Stonecroft Country Inn">
                                  Stonecroft Country Inn
                                </option>
                                <option value="Steamboat Inn">
                                  Steamboat Inn
                                </option>
                                <option value="Mermaid Inn of Mystic">
                                  Mermaid Inn of Mystic
                                </option>
                                <option value="Harbor View Landing">
                                  Harbor View Landing
                                </option>
                                <option value="Whitehall Mansion Inn">
                                  Whitehall Mansion Inn
                                </option>
                                <option value="The Inn at Stonington">
                                  The Inn at Stonington
                                </option>
                                <option value="Inn at Mystic">
                                  Inn at Mystic
                                </option>
                                <option value="Holiday Inn Express Mystic">
                                  Holiday Inn Express Mystic
                                </option>
                                <option value="The Taber Inn">
                                  The Taber Inn
                                </option>
                                <option value="Spark by Hilton Mystic Groton">
                                  Spark by Hilton Mystic
                                  Groton
                                </option>
                                <option value="Ramada by Wyndham Groton">
                                  Ramada by Wyndham Groton
                                </option>
                                <option value="Gold Star Inn and Suites">
                                  Gold Star Inn and Suites
                                </option>
                                <option value="Mystic River Hotel and Suites">
                                  Mystic River Hotel and
                                  Suites
                                </option>
                                <option value="Americas Best Value Inn New London Mystic">
                                  Americas Best Value Inn
                                  New London Mystic
                                </option>
                                <option value="Americas Best Value Inn Stonington Mystic">
                                  Americas Best Value Inn
                                  Stonington Mystic
                                </option>
                                <option value="Flagship Inn and Suites">
                                  Flagship Inn and Suites
                                </option>
                                <option value="Quality Inn Mystic">
                                  Quality Inn Mystic
                                </option>
                                <option value="Howard Johnsons by Wyndham Mystic">
                                  Howard Johnsons by Wyndham
                                  Mystic
                                </option>
                                <option value="NAVY LODGE (Requires DoD ID)">
                                  NAVY LODGE (Requires DoD
                                  ID)
                                </option>
                                <option value="NAVY Gateway Inn (Requires DoD ID)">
                                  NAVY Gateway Inn (Requires
                                  DoD ID)
                                </option>
                                <option value="NAVY Chalet (Requires DoD ID)">
                                  NAVY Chalet (Requires DoD
                                  ID)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Second hotel preference
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                id="second_hotel"
                                {...register(
                                  `persons.[${index}].second_hotel`
                                )}
                              >
                                <option value="">
                                  Select
                                </option>
                                <option value="Mystic Marriott Hotel and Spa">
                                  Mystic Marriott Hotel and
                                  Spa
                                </option>
                                <option value="Hilton Mystic">
                                  Hilton Mystic
                                </option>
                                <option value="Hampton Inn and Suites Mystic">
                                  Hampton Inn and Suites
                                  Mystic
                                </option>
                                <option value="Hyatt Place Mystic">
                                  Hyatt Place Mystic
                                </option>
                                <option value="Residence Inn by Marriott Mystic Groton">
                                  Residence Inn by Marriott
                                  Mystic Groton
                                </option>
                                <option value="Hampton Inn Groton">
                                  Hampton Inn Groton
                                </option>
                                <option value="The Whalers Inn">
                                  The Whalers Inn
                                </option>
                                <option value="Saybrook Point Resort & Marina">
                                  Saybrook Point Resort &
                                  Marina
                                </option>
                                <option value="House of 1833 Bed and Breakfast">
                                  House of 1833 Bed and
                                  Breakfast
                                </option>
                                <option value="Stonecroft Country Inn">
                                  Stonecroft Country Inn
                                </option>
                                <option value="Steamboat Inn">
                                  Steamboat Inn
                                </option>
                                <option value="Mermaid Inn of Mystic">
                                  Mermaid Inn of Mystic
                                </option>
                                <option value="Harbor View Landing">
                                  Harbor View Landing
                                </option>
                                <option value="Whitehall Mansion Inn">
                                  Whitehall Mansion Inn
                                </option>
                                <option value="The Inn at Stonington">
                                  The Inn at Stonington
                                </option>
                                <option value="Inn at Mystic">
                                  Inn at Mystic
                                </option>
                                <option value="Holiday Inn Express Mystic">
                                  Holiday Inn Express Mystic
                                </option>
                                <option value="The Taber Inn">
                                  The Taber Inn
                                </option>
                                <option value="Spark by Hilton Mystic Groton">
                                  Spark by Hilton Mystic
                                  Groton
                                </option>
                                <option value="Ramada by Wyndham Groton">
                                  Ramada by Wyndham Groton
                                </option>
                                <option value="Gold Star Inn and Suites">
                                  Gold Star Inn and Suites
                                </option>
                                <option value="Mystic River Hotel and Suites">
                                  Mystic River Hotel and
                                  Suites
                                </option>
                                <option value="Americas Best Value Inn New London Mystic">
                                  Americas Best Value Inn
                                  New London Mystic
                                </option>
                                <option value="Americas Best Value Inn Stonington Mystic">
                                  Americas Best Value Inn
                                  Stonington Mystic
                                </option>
                                <option value="Flagship Inn and Suites">
                                  Flagship Inn and Suites
                                </option>
                                <option value="Quality Inn Mystic">
                                  Quality Inn Mystic
                                </option>
                                <option value="Howard Johnsons by Wyndham Mystic">
                                  Howard Johnsons by Wyndham
                                  Mystic
                                </option>
                                <option value="NAVY LODGE (Requires DoD ID)">
                                  NAVY LODGE (Requires DoD
                                  ID)
                                </option>
                                <option value="NAVY Gateway Inn (Requires DoD ID)">
                                  NAVY Gateway Inn (Requires
                                  DoD ID)
                                </option>
                                <option value="NAVY Chalet (Requires DoD ID)">
                                  NAVY Chalet (Requires DoD
                                  ID)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Third hotel preference
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                id="third_hotel"
                                {...register(
                                  `persons.[${index}].third_hotel`
                                )}
                              >
                                <option value="">
                                  Select
                                </option>
                                <option value="Mystic Marriott Hotel and Spa">
                                  Mystic Marriott Hotel and
                                  Spa
                                </option>
                                <option value="Hilton Mystic">
                                  Hilton Mystic
                                </option>
                                <option value="Hampton Inn and Suites Mystic">
                                  Hampton Inn and Suites
                                  Mystic
                                </option>
                                <option value="Hyatt Place Mystic">
                                  Hyatt Place Mystic
                                </option>
                                <option value="Residence Inn by Marriott Mystic Groton">
                                  Residence Inn by Marriott
                                  Mystic Groton
                                </option>
                                <option value="Hampton Inn Groton">
                                  Hampton Inn Groton
                                </option>
                                <option value="The Whalers Inn">
                                  The Whalers Inn
                                </option>
                                <option value="Saybrook Point Resort & Marina">
                                  Saybrook Point Resort &
                                  Marina
                                </option>
                                <option value="House of 1833 Bed and Breakfast">
                                  House of 1833 Bed and
                                  Breakfast
                                </option>
                                <option value="Stonecroft Country Inn">
                                  Stonecroft Country Inn
                                </option>
                                <option value="Steamboat Inn">
                                  Steamboat Inn
                                </option>
                                <option value="Mermaid Inn of Mystic">
                                  Mermaid Inn of Mystic
                                </option>
                                <option value="Harbor View Landing">
                                  Harbor View Landing
                                </option>
                                <option value="Whitehall Mansion Inn">
                                  Whitehall Mansion Inn
                                </option>
                                <option value="The Inn at Stonington">
                                  The Inn at Stonington
                                </option>
                                <option value="Inn at Mystic">
                                  Inn at Mystic
                                </option>
                                <option value="Holiday Inn Express Mystic">
                                  Holiday Inn Express Mystic
                                </option>
                                <option value="The Taber Inn">
                                  The Taber Inn
                                </option>
                                <option value="Spark by Hilton Mystic Groton">
                                  Spark by Hilton Mystic
                                  Groton
                                </option>
                                <option value="Ramada by Wyndham Groton">
                                  Ramada by Wyndham Groton
                                </option>
                                <option value="Gold Star Inn and Suites">
                                  Gold Star Inn and Suites
                                </option>
                                <option value="Mystic River Hotel and Suites">
                                  Mystic River Hotel and
                                  Suites
                                </option>
                                <option value="Americas Best Value Inn New London Mystic">
                                  Americas Best Value Inn
                                  New London Mystic
                                </option>
                                <option value="Americas Best Value Inn Stonington Mystic">
                                  Americas Best Value Inn
                                  Stonington Mystic
                                </option>
                                <option value="Flagship Inn and Suites">
                                  Flagship Inn and Suites
                                </option>
                                <option value="Quality Inn Mystic">
                                  Quality Inn Mystic
                                </option>
                                <option value="Howard Johnsons by Wyndham Mystic">
                                  Howard Johnsons by Wyndham
                                  Mystic
                                </option>
                                <option value="NAVY LODGE (Requires DoD ID)">
                                  NAVY LODGE (Requires DoD
                                  ID)
                                </option>
                                <option value="NAVY Gateway Inn (Requires DoD ID)">
                                  NAVY Gateway Inn (Requires
                                  DoD ID)
                                </option>
                                <option value="NAVY Chalet (Requires DoD ID)">
                                  NAVY Chalet (Requires DoD
                                  ID)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Hotel loyalty program name 1
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                name={`persons.[${index}].hotel_loyalty_1`}
                                {...register(
                                  `persons.[${index}].hotel_loyalty_1`
                                )}
                              >
                                <option value="">
                                  Select
                                </option>
                                <option value="Marriott">
                                  Marriott
                                </option>
                                <option value="Hilton">
                                  Hilton
                                </option>
                                <option value="Hyatt">
                                  Hyatt
                                </option>
                                <option value="Wydham">
                                  Wydham
                                </option>
                                <option value="IHG">
                                  IHG
                                </option>
                                <option value="OTHER">
                                  OTHER (Specify)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                      {watchObject.persons[index]
                          .hotel_loyalty_1 === "OTHER" && (
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Other hotel loyalty program
                                name 1
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`persons.[${index}].other_hotel_loyalty_1`}
                                {...register(
                                  `persons.[${index}].other_hotel_loyalty_1`
                                )}
                              />
                            </Form.Group>
                          </Col>
                        )}
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Hotel loyalty program number 1
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`persons.[${index}].hotel_loyalty_1_number`}
                            {...register(
                              `persons.[${index}].hotel_loyalty_1_number`
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Hotel loyalty program name 2
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                name={`persons.[${index}].hotel_loyalty_2`}
                                {...register(
                                  `persons.[${index}].hotel_loyalty_2`
                                )}
                              >
                                <option value="">
                                  Select
                                </option>
                                <option value="Marriott">
                                  Marriott
                                </option>
                                <option value="Hilton">
                                  Hilton
                                </option>
                                <option value="Hyatt">
                                  Hyatt
                                </option>
                                <option value="Wydham">
                                  Wydham
                                </option>
                                <option value="IHG">
                                  IHG
                                </option>
                                <option value="OTHER">
                                  OTHER (Specify)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                      {watchObject.persons[index]
                          .hotel_loyalty_2 === "OTHER" && (
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Other hotel loyalty program
                                name 2
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`persons.[${index}].other_hotel_loyalty_2`}
                                {...register(
                                  `persons.[${index}].other_hotel_loyalty_2`
                                )}
                              />
                            </Form.Group>
                          </Col>
                        )}
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Hotel loyalty program number 2
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`persons.[${index}].hotel_loyalty_2_number`}
                            {...register(
                              `persons.[${index}].hotel_loyalty_2_number`
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Hotel loyalty program name 3
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                name={`persons.[${index}].hotel_loyalty_3`}
                                {...register(
                                  `persons.[${index}].hotel_loyalty_3`
                                )}
                              >
                                <option value="">
                                  Select
                                </option>
                                <option value="Marriott">
                                  Marriott
                                </option>
                                <option value="Hilton">
                                  Hilton
                                </option>
                                <option value="Hyatt">
                                  Hyatt
                                </option>
                                <option value="Wydham">
                                  Wydham
                                </option>
                                <option value="IHG">
                                  IHG
                                </option>
                                <option value="OTHER">
                                  OTHER (Specify)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                      {watchObject.persons[index]
                          .hotel_loyalty_3 === "OTHER" && (
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Other hotel loyalty program
                                name 3
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`persons.[${index}].other_hotel_loyalty_3`}
                                {...register(
                                  `persons.[${index}].other_hotel_loyalty_3`
                                )}
                              />
                            </Form.Group>
                          </Col>
                        )}
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Hotel loyalty program number 1
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`persons.[${index}].hotel_loyalty_3_number`}
                            {...register(
                              `persons.[${index}].hotel_loyalty_3_number`
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                )}
            </div>

            <div>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Do you request a Rental Car Quote from Uniglobe Travel?
                    </Form.Label>
                    <fieldset>
                      <input
                        className="radio"
                        type="radio"
                        label="Yes"
                        id={`persons.[${index}].car_quote_yes`}
                        name={`persons.[${index}].car_quote_no`}
                        value="Yes"
                        {...register(
                          `persons.[${index}].car_quote`
                        )}
                      />
                      <label
                        className="radioLabel"
                        htmlFor={`persons.[${index}].car_quote_yes`}
                      >
                        Yes
                      </label>
                      <input
                        className="radio"
                        type="radio"
                        id={`persons.[${index}].car_quote_no`}
                        name={`persons.[${index}].car_quote_no`}
                        value="No"
                        {...register(
                          `persons.[${index}].car_quote`
                        )}
                        style={{ marginLeft: "2rem" }}
                      />
                      <label
                        className="radioLabel"
                        htmlFor={`persons.[${index}].car_quote_no`}
                      >
                        No
                      </label>
                    </fieldset>
                  </Form.Group>
                </Col>
              </Row>

              {watchObject.persons[index].car_quote ===
                  "Yes" && (
                  <div className="fade-in">
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            How cars do you require?
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                id="how_many_cars"
                                {...register(
                                  `persons.[${index}].how_many_cars`
                                )}
                              >
                                <option value="">
                                  Select
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">
                                  10
                                </option>
                                <option value="11">
                                  11
                                </option>
                                <option value="12">
                                  12
                                </option>
                                <option value="OTHER">
                                  OTHER (Specify)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                      
                        {watchObject.persons[index].how_many_cars === "OTHER" && (
                            <Col className="fade-in">
                              <Form.Group>
                                <Form.Label>
                                  Other number of cars
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name={`persons.[${index}].other_number_of_cars`}
                                  {...register(
                                    `persons.[${index}].other_number_of_cars`
                                  )}
                                />
                              </Form.Group>
                            </Col>
                          )}
                      
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Rental car loyalty program name 1
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                name={`persons.[${index}].car_loyalty_1`}
                                {...register(
                                  `persons.[${index}].car_loyalty_1`
                                )}
                              >
                                 <option value="">Select</option>
                                  <option value="Hertz">Hertz</option>
                                  <option value="Avis">Avis</option>
                                  <option value="Budget">Budget</option>
                                  <option value="Alamo">Alamo</option>
                                  <option value="Dollar">Dollar</option>
                                  <option value="National">National</option>
                                  <option value="Enterprise">Enterprise</option>
                                <option value="OTHER">
                                  OTHER (Specify)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                      {watchObject.persons[index]
                          .car_loyalty_1 === "OTHER" && (
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Other rental car loyalty program name 1
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`persons.[${index}].other_car_loyalty_1`}
                                {...register(
                                  `persons.[${index}].other_car_loyalty_1`
                                )}
                              />
                            </Form.Group>
                          </Col>
                        )}
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Rental car loyalty program number 1
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`persons.[${index}].cart_loyalty_1_number`}
                            {...register(
                              `persons.[${index}].car_loyalty_1_number`
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Rental car loyalty program name 2
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                name={`persons.[${index}].car_loyalty_2`}
                                {...register(
                                  `persons.[${index}].car_loyalty_2`
                                )}
                              >
                                 <option value="">Select</option>
                                  <option value="Hertz">Hertz</option>
                                  <option value="Avis">Avis</option>
                                  <option value="Budget">Budget</option>
                                  <option value="Alamo">Alamo</option>
                                  <option value="Dollar">Dollar</option>
                                  <option value="National">National</option>
                                  <option value="Enterprise">Enterprise</option>
                                <option value="OTHER">
                                  OTHER (Specify)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                      {watchObject.persons[index]
                          .car_loyalty_2 === "OTHER" && (
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Other rental car loyalty program name 2
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`persons.[${index}].other_car_loyalty_2`}
                                {...register(
                                  `persons.[${index}].other_car_loyalty_2`
                                )}
                              />
                            </Form.Group>
                          </Col>
                        )}
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Rental car loyalty program number 2
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`persons.[${index}].car_loyalty_2_number`}
                            {...register(
                              `persons.[${index}].car_loyalty_2_number`
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Rental car loyalty program name 3
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                as="select"
                                name={`persons.[${index}].car_loyalty_3`}
                                {...register(
                                  `persons.[${index}].car_loyalty_3`
                                )}
                              > <option value="">Select</option>
                                  <option value="Hertz">Hertz</option>
                                  <option value="Avis">Avis</option>
                                  <option value="Budget">Budget</option>
                                  <option value="Alamo">Alamo</option>
                                  <option value="Dollar">Dollar</option>
                                  <option value="National">National</option>
                                  <option value="Enterprise">Enterprise</option>
                                <option value="OTHER">
                                  OTHER (Specify)
                                </option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Col>
                      {watchObject.persons[index]
                          .car_loyalty_3 === "OTHER" && (
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Other rental car loyalty program name 3
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`persons.[${index}].other_car_loyalty_3`}
                                {...register(
                                  `persons.[${index}].other_car_loyalty_3`
                                )}
                              />
                            </Form.Group>
                          </Col>
                        )}
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Rental car loyalty program number 3
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`persons.[${index}].car_loyalty_3_number`}
                            {...register(
                              `persons.[${index}].car_loyalty_3_number`
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                  </div>
                )}
            </div>
            <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                Comments for Uniglobe Travel
              </Form.Label>
              <Form.Control
                type="text"
                name={`persons.[${index}].uniglobe_comments`}
                {...register(
                  `persons.[${index}].uniglobe_comments`
                )}
              />
            </Form.Group>
          </Col>
        </Row>
          </div>
          </div>
          </>
            ))}
            {watchObject.uniglobe_acknowledge === "Yes" && (
              <Row>
                <Button
                  type="submit"
                  variant="primary"
                  form="travel-request-form"
                  disabled={disableSubmit}
                  className="submitButton teamButton"
                >
                  {succeeded
                    ? "Message submitted"
                    : processing
                    ? "SUBMITTING..."
                    : "SUBMIT"}
                </Button>
              </Row>
            )}
            
          </form>
        </>
      )}
    </>
  )
}
