import React from "react";

const cities = [
  "Select",
  "Boise",
  "Meridian",
  "Nampa",
  "Idaho Falls",
  "Caldwell",
  "Pocatello",
  "Coeur d'Alene",
  "Twin Falls",
  "Post Falls",
  "Rexburg",
  "Lewiston",
  "Eagle",
  "Kuna",
  "Moscow",
  "Ammon",
  "Hayden",
  "Mountain Home",
  "Chubbuck",
  "Star",
  "Jerome",
  "Garden City",
  "Blackfoot",
  "Burley",
  "Middleton",
  "Rathdrum",
  "Sandpoint",
  "Hailey",
  "Payette",
  "Emmett",
  "Fruitland",
"OTHER (Specify)"
];

export default function StateList() {
  return { cities };
}
