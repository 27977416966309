import React, { useState, useEffect } from "react"
import { useForm, FormProvider, useFieldArray } from "react-hook-form"
import { Form, Button, Row, Col, Alert } from "react-bootstrap"
import StateList from "./StateList"
import CityList from "./CityList"
import PlusSymbol from "./plus-symbol.svg"
import MinusSymbol from "./minus-symbol.svg"

export default function ModifyForm() {

    const [succeeded, setSucceeded] = useState(false)
    const [error, setError] = useState(null)
    const [processing, setProcessing] = useState("")
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [autoFirstName, setAutoFirstName] = useState("")
  
    const Required = () => <span style={styles.formError}>*</span>
  
    const styles = {
      formError: {
        color: "red",
        fontSize: "0.8rem",
        fontWeight: 700,
      },
    }

    const {
        register,
        formState,
        reset,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        control,
        setValue,
      } = useForm({
        mode: "onSubmit",
      });

      const submitForm = data => {
    
        setProcessing(true)
        setDisableSubmit(true)
        console.log('data', data);

        fetch(
          `https://script.google.com/macros/s/AKfycbxICXtAzb3EG7sWvnDTaVPxeZ0LbrCBpEJ2EsHq6IyW3BLXZ696SJDDlSI3UgirFf5HnQ/exec`,
          {
            method: "POST",
            body: JSON.stringify(data),
          }
        )
          .then(response => {
            if (!response.ok) {
              throw new Error("Network response was not ok")
            }
            return response.text()
          })
          .then(result => {
            console.log(result)
            fetch('/.netlify/functions/mailgun-modify-christening-request', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then(res => {
            console.log('mailgun response', res);
            if (res.status === 200) {
              console.log('Data submitted to Mailgun');
              setSucceeded(true);
              reset();
            } else {
                console.log('Error submiting to Mailgun')
            }
        })
          })
          .catch((error) => {
          setSucceeded(false);
          setDisableSubmit(false);
          console.log('Mailgun error!', error.message);
          alert("Error: " + error.message)
        })
      }
      
      const SubmitSuccessMessage = () => (
        <div className="submit-success-message text-center">
          <Alert variant="success">
            <h3>Thank you for your submission.</h3>
            <p>A representative will be in touch shortly.</p>
          </Alert>
        </div>
      )


  return (
    <>
    {succeeded ? <SubmitSuccessMessage /> : (
      <form
      id="modify-request"
      name="modify-request"
      onSubmit={handleSubmit(submitForm)}
    >
      <Row className="fade-in">
        <Col sm={12} md={6}>
          <Form.Group>
            <Form.Label>
              Requester First Name
              <Required />
            </Form.Label>
            <Form.Control
              className="formField"
              type="text"
              name="first_name"
              {...register("first_name", {
                required: true,
              })}
            />{" "}
            {errors.first_name && (
              <span style={styles.formError}>First Name is required.</span>
            )}
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group>
            <Form.Label>
              Requester Last Name
              <Required />
            </Form.Label>
            <Form.Control
              className="formField"
              type="text"
              name="last_name"
              {...register("last_name", {
                required: true,
              })}
            />{" "}
            {errors.last_name && (
              <span style={styles.formError}>Last Name is required.</span>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <Form.Group>
            <Form.Label>
              Requester Email Address
              <Required />
            </Form.Label>
            <Form.Control
              className="formField"
              type="email"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Enter a valid e-mail address",
                },
              })}
            />{" "}
            {errors.email && (
              <span style={styles.formError}>
                {errors.email.message}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group>
            <Form.Label>
              Requester Phone Number (mobile preferred)
              <Required />
            </Form.Label>
            <Form.Control
              //placeholder={errors.phone ? errors.phone.message : ""}
              className="formField"
              type="text"
              name="phone"
              {...register("phone", {
                required: "Phone number is required.",
                pattern: {
                  value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  message: "Please enter a valid phone number.",
                },
              })}
            />{" "}
            {errors.phone && (
              <span style={styles.formError}>
                {errors.phone.message}
              </span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="fade-in">
        <Col>
          <Form.Group>
            <Form.Label>
              Please provide a detailed explanation of the changes you would
              like to make<Required />
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name={`modification_request`}
              {...register(`modification_request`,
              {
                required: true,
                message: "Changes are required."
              })}
            />
            {errors.modification_request && (
              <span style={styles.formError}>
                {errors.modification_request.message}
              </span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            type="submit"
            variant="primary"
            form="modify-request"
            //disabled={!formState.isValid}
            className="submitButton teamButton"
          >
            {succeeded
              ? "Message submitted"
              : processing
              ? "SUBMITTING..."
              : "SUBMIT"}
          </Button>
        </Col>
      </Row>
    </form>
    )}
    </>
  )
}
