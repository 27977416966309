import React from "react"
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import PageTitle from '../components/PageTitle/PageTitle'
import { Container } from 'react-bootstrap'
import ChristeningRequestForm from '../components/ChristeningRequestForm/ChristeningRequestForm'
import ModifyForm from '../components/ChristeningRequestForm/ModifyForm'
import { useForm, FormProvider, useFieldArray } from "react-hook-form"
import { Form, Button, Row, Col, Alert } from "react-bootstrap"

const ChristeningRequest = ({ data }) => {
  const Required = () => <span style={styles.formError}>*</span>
  
  const styles = {
    formError: {
      color: "red",
      fontSize: "0.8rem",
      fontWeight: 700,
    },
  }

  const {
      register,
      formState,
      reset,
      formState: { errors },
      watch,
      handleSubmit,
      getValues,
      control,
      setValue,
    } = useForm({
      mode: "onSubmit",
    });

    const watchObject = watch();

  return (

<Layout>
<SEO title="USS IDAHO Christening Ticker Request"/>
<Container fluid className="container-fluid-full">
  <PageTitle pageTitle="USS IDAHO Christening Ticket Request" >
    {/* {page.pageTitle} */}
  </PageTitle>
  <ContentBlock>

  <form>
    <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Request Type
                      <Required />
                    </Form.Label>
                    <fieldset>
                      <input
                        className="radio"
                        type="radio"
                        label="New Request"
                        id="new_request"
                        value="new_request"
                        {...register("request_type")}
                        defaultChecked
                      />
                      <label className="radioLabel" htmlFor="new_request">
                        New Ticket Request
                      </label>
                      <input
                        className="radio"
                        type="radio"
                        id="modify_request"
                        value="modify_request"
                        {...register("request_type")}
                        style={{ marginLeft: "2rem" }}
                      />
                      <label className="radioLabel" htmlFor="modify_request">
                        Modify Existing Request
                      </label>
                    </fieldset>
                  </Form.Group>
                </Col>
              </Row>
  </form>

  {watchObject.request_type === "modify_request" ? (
              <ModifyForm /> 
            ) : (
            
    <ChristeningRequestForm />
            )}
      
    </ContentBlock>
    </Container>
</Layout>
  )
}


export default ChristeningRequest
