import React, { useState, useEffect } from "react"
import { useForm, FormProvider, useFieldArray, set } from "react-hook-form"
import { Form, Button, Row, Col, Alert } from "react-bootstrap"
import StateList from "./StateList"
import CityList from "./CityList"
import TravelAssistanceForm from "./TravelAssistanceForm"
import PlusSymbol from "./plus-symbol.svg"
import MinusSymbol from "./minus-symbol.svg"
import ModifyForm from "./ModifyForm"

export default function ChristeningRequestForm() {
  const [message, setMessage] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [ticketForSelf, setTicketForSelf] = useState("")

  const [travelAssistance, setTravelAssitance] = useState(false);
  const [travelAssistanceData, setTravelAssistanceData] = useState({});

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    // defaultValues: {
    //     license: [],
    //     businessCategory: [],
    // }
  })

  // use defaultValues to set values for conditional fields which will not be registered to the DOM unless exposed
  // this is to avoid server-side errors for undefined keys
  const emptyPerson = [
    {
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
    },
  ]

  const {
    register,
    formState: { errors },
    reset,
    //errors,
    watch,
    handleSubmit,
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      person: [
        {
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          relationship: "",
          drivers_license_state: "",
          drivers_license_number: "",
          optional_info: "",
          star_card: "",
          date_of_birth: "",
          city_of_birth: "",
          state_of_birth: "",
          country_of_birth: "",
          us_citizen: "",
          country_of_citizenship: "",
          other_country_of_citizenship: "",
          us_passport: "",
          passport_number: "",
          other_country_passport: "",
          other_country_passport_number: "",
          veteran: "",
          branch: "",
          status: "",
          rank: "",
          dod_id_number: "",
          comments: "",
          veteran_service_org: [],
          other_veteran_service_org: "",
          subvet_base: "",
          travel_assistance: "",
        },
      ],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "person",
  })

  const watchObject = watch();

  console.log(watchObject)

  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState("")
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

const toggleSuccessMessage = (show) => {
  setShowSuccessMessage(show);
};
  
  const Required = () => <span style={styles.formError}>*</span>

  const styles = {
    formError: {
      color: "red",
      fontSize: "0.8rem",
      fontWeight: 700,
    },
    formLabel: {
      fontWeight: 700,
      fontSize: "1.125rem",
    }
  }

  const handleChange = async event => {
    setError(event.error ? event.error.message : "")
    console.log(watchObject)
  }

  const processFormData = data => {
    // Clone the data object to avoid mutating the original
    const processedData = { ...data }

    // Check if person is an array
    if (Array.isArray(processedData.person)) {
      // Iterate over each person object
      processedData.person = processedData.person.map(person => {
        // Check if veteran_service_org is an array
        if (Array.isArray(person.veteran_service_org)) {
          // Convert the array to a comma-delimited string
          person.veteran_service_org = person.veteran_service_org.join(", ")
        }
        return person
      })
    }

    return processedData
  }

  const submitForm = data => {
    // Process the form data
    const processedData = processFormData(data)

    setProcessing(true)
    setDisableSubmit(true)

    fetch(
      `https://script.google.com/macros/s/AKfycby-fIC_cjhzwpoCRidKFKdciwg_H8tSgzZRdaYigtLIoViyN0dguGnIZI6o06QqnYvY/exec`,
      {
        method: "POST",
        body: JSON.stringify(processedData),
      }
    )
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.text()
      })
      
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        setSucceeded(false)
        setDisableSubmit(false)
        console.error("Error:", error)
        alert("Error: " + error.message)
      })
      
      fetch('/.netlify/functions/mailgun-christening-request', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(processedData),
      })
      .then(res => {
        console.log('mailgun response', res);
        if (res.status === 200) {
          console.log('Data submitted to Mailgun');
          setSucceeded(true);
          reset();
        } else {
            console.log('Error submiting to Mailgun')
        }
    }).then(() => {
      if (processedData.travel_assistance === "Yes") {
        setTravelAssistanceData(processedData);
        setTravelAssitance(true);
    }})  
    .catch((error) => {
      setSucceeded(false);
      setDisableSubmit(false);
      console.log('Mailgun error!', error.message);
      alert("Error: " + error.message)
    })
  }

  const SubmitSuccessMessage = () => (
    <div className="submit-success-message text-center">
      <Alert variant="success">
        <h3>Thank you for your submission.</h3>
        <p>A copy of your request has been emailed to you.</p>
        <p>A representative will be in touch shortly.</p>
        <p>Invites and RSVP will ONLY be sent to the Requester.</p>
        <p>
          Tickets to the Christening of USS IDAHO SSN 799 are controlled by and
          at the discretion of the General Dynamics Electric Boat Shipyard. The USS IDAHO
          Commissioning Committee will do everything it can do to advocate on
          your behalf but we cannot make any guarantees, implied or otherwise,
          as to whether you will be offered and/or receive a ticket or the
          number of tickets you requested from Electric Boat.
        </p>
      </Alert>
    </div>
  )
  return (
    <>
      {succeeded && !travelAssistance ? (
       <>
          <SubmitSuccessMessage />
       </>
      ) 
      : travelAssistance ? (
        <>
          <TravelAssistanceForm
            travelAssistanceData={travelAssistanceData}
            toggleSuccessMessage={toggleSuccessMessage}
            />
        </>
      )
      : (
        <>
            <Alert variant="warning">
              <p style={{marginBottom: 0, fontSize: '1.125rem', fontWeight: 'bold'}}>
            Tickets to the Christening of USS IDAHO SSN 799 are controlled by
            and at the discretion of General Dynamics Electric Boat Shipyard. The USS IDAHO
            Commissioning Committee will do everything it can do to advocate on
            your behalf but we cannot make any guarantees, implied or otherwise,
            as to whether you will be offered and/or receive a ticket or the
            number of tickets you requested from Electric Boat.
            </p>
            <div className="mt-4">
              <Form.Group>
                  <fieldset>
                    <input
                      className="radio"
                      type="checkbox"
                      inline
                      label="Yes"
                      id={`acknowledge_yes`}
                      name={`acknowledge_yes`}
                      value="Yes"
                      {...register(
                        `acknowledge`
                      )}
                    />
                    <label
                      className="radioLabel"
                      htmlFor={`acknowledge_yes`}
                      style={{color: 'black', fontWeight: 'bold', fontSize: 'larger'}}
                    >
                      I acknowlege and agree to the statement above.
                    </label>
                  </fieldset>
                </Form.Group>
            </div>
            </Alert>
          {watchObject.acknowledge === "Yes" && 
            (
          <div className="fade-in">
            <Alert variant="warning">
                <p style={{fontWeight: 'bold', color: 'black'}}>For this ticket request form, you will be asked for the Driver’s License, Passport, eMail address and Mobile Phone Information for each person needing a ticket that is 18 or older. Please have this ready before proceeding.</p>
              </Alert>
            <p>
              <strong>
                Invites and RSVP will ONLY be sent to the Requester.
              </strong>
            </p>
            <p>
              <Required /> <strong>Indicates a required field.</strong>
            </p>
  
            <form
              onSubmit={handleSubmit(submitForm)}
              id="new-request-form"
              name="new-request-form"
              style={{fontSize: '1.125rem'}}
            >
              <div>
                <div style={{
                        marginBottom: "2rem",
                        border: "1px solid lightgray",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                      }}>
                         <Row>
                        <Col>
                          <h4>Request Information</h4>
                        </Col>
                      </Row>
                      <Form.Control
                        type="hidden"
                        {...register("timestamp")}
                        value={new Date().toISOString()}
                      />
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                       How many tickets to the USS IDAHO <strong>Christening</strong> are you requesting from Electric Boat?
                        <Required />
                      </Form.Label>
                      <Form.Row>
                        <Col sm={3}>
                          <Form.Control
                            as="select"
                            id="number_of_tickets"
                            name="number_of_tickets"
                            {...register("number_of_tickets")}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="other">Other (Specify)</option>
                          </Form.Control>
                        </Col>
                      </Form.Row>
                    </Form.Group>
                  </Col>
                </Row>
                {watchObject.number_of_tickets === "other" ? (
                  <Row className="fade-in">
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Please specify the number of tickets you are requesting
                        </Form.Label>
                        <Form.Row>
                          <Col sm={3}>
                            <Form.Control
                              type="number"
                              name="other_number_of_tickets"
                              placeholder="13"
                              min={13}
                              {...register("other_number_of_tickets")}
                            />
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Group>
                      <Form.Label>
                        Requester First Name
                        <Required />
                      </Form.Label>
                      <Form.Control
                        className="formField"
                        type="text"
                        name="requester_first_name"
                        {...register("requester_first_name", {
                          required: true,
                        })}
                      />{" "}
                      {errors.requester_first_name && (
                        <span style={styles.formError}>
                          First Name is required.
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group>
                      <Form.Label>
                        Requester Last Name
                        <Required />
                      </Form.Label>
                      <Form.Control
                        className="formField"
                        type="text"
                        name="requester_last_name"
                        {...register("requester_last_name", {
                          required: true,
                        })}
                      />{" "}
                      {errors.requester_last_name && (
                        <span style={styles.formError}>
                          Last Name is required.
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
  
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Group>
                      <Form.Label>
                        Requester Email Address
                        <Required />
                      </Form.Label>
                      <Form.Control
                        className="formField"
                        type="email"
                        {...register("requester_email", {
                          required: "Email is required.",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Enter a valid e-mail address",
                          },
                        })}
                      />{" "}
                      {errors.requester_email && (
                        <span style={styles.formError}>
                          {errors.requester_email.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group>
                      <Form.Label>
                        Requester Phone Number (mobile preferred)
                        <Required />
                      </Form.Label>
                      <Form.Control
                        //placeholder={errors.requester_phone ? errors.requester_phone.message : ""}
                        className="formField"
                        type="text"
                        name="requester_phone"
                        {...register("requester_phone", {
                          required: "Phone number is required.",
                          pattern: {
                            value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                            message: "Please enter a valid phone number.",
                          },
                        })}
                      />{" "}
                      {errors.requester_phone && (
                        <span style={styles.formError}>
                          {errors.requester_phone.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
  
                <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Street Address 1</Form.Label>
                            <Form.Control
                              className="formField"
                              type="text"
                              {...register(`requester_street_address_1`)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Street Address 2</Form.Label>
                            <Form.Control
                              className="formField"
                              type="text"
                              {...register(`requester_street_address_2`)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Street Address 3</Form.Label>
                            <Form.Control
                              className="formField"
                              type="text"
                              {...register(`requester_street_address_3`)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col sm={12} md={4}>
                          <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              as="select"
                              defaultValue="Select"
                              {...register(`requester_city`)}
                            >
                              {CityList().cities.map(index => (
                                <option key={index} value={index}>
                                  {index}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        {watchObject.requester_city ===
                            "OTHER (Specify)" && (
                            <Col>
                              <Form.Group>
                                <Form.Label>Other City</Form.Label>
                                <Form.Control
                                  className="formField"
                                  type="text"
                                  {...register('requester_other_city')}
                                />
                              </Form.Group>
                            </Col>
                          )}
                      </Row>
  
                      <Row>
                        <Col sm={12} md={4}>
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue="ID"
                            {...register(`requester_state`)}
                          >
                            {StateList().states.map(index => (
                              <option key={index} value={index}>
                                {index}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Group>
                            <Form.Label>Zip</Form.Label>
                            <Form.Control
                              className="formField"
                              type="text"
                              {...register(`requester_zip`)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Company or organization</Form.Label>
                      <Form.Row>
                        <Col>
                          <Form.Control
                            as="select"
                            id="company_or_organization"
                            {...register("company_or_organization")}
                          >
                            <option value="">Select</option>
                            <option value="USS IDAHO Commissioning Committee (USSICC)">
                              USS IDAHO Commissioning Committee (USSICC)
                            </option>
                            <option value="USS IDAHO Commissioning Advisory Board (USSICAB)">
                              USS IDAHO Commissioning Advisory Board (USSICAB)
                            </option>
                            <option value="Kootenai Tribe of Idaho">Kootenai Tribe of Idaho</option>
                            <option value="Coeur d'Alene Tribe (Schitsu’umsh)">Coeur d'Alene Tribe (Schitsu’umsh)</option>
                            <option value="Nez Perce Tribe (Nimiipuu)">Nez Perce Tribe (Nimiipuu)</option>
                            <option value="Shoshone-Paiute Tribes">Shoshone-Paiute Tribes</option>
                            <option value="Shoshone-Bannock Tribes">Shoshone-Bannock Tribes</option>
                            <option value="Idaho Power">Idaho Power</option>
                            <option value="Albertsons corporation">
                              Albertsons corporation
                            </option>
                            <option value="United Heritage Ins.">
                              United Heritage Ins.
                            </option>
                            <option value="Bank of Idaho">Bank of Idaho</option>
                            <option value="First Interstate Bank">
                              First Interstate Bank
                            </option>
                            <option value="Con Paulos Chevrolet">
                              Con Paulos Chevrolet
                            </option>
                            <option value="ICCU">ICCU</option>
                            <option value="Agribeef">Agribeef</option>
                            <option value="J and K Albertson Foundation">
                              J and K Albertson Foundation
                            </option>
                            <option value="Battelle Energy (INL)">
                              Battelle Energy (INL)
                            </option>
                            <option value="Hecla Mining">Hecla Mining</option>
                            <option value="Schweitzer Engineering">
                              Schweitzer Engineering
                            </option>
                            <option value="Delta Dental">Delta Dental</option>
                            <option value="JR Simplot Co.">JR Simplot Co.</option>
                            <option value="Boise Cascade">Boise Cascade</option>
                            <option value="Avista Power">Avista Power</option>
                            <option value="Buck Knives">Buck Knives</option>
                            <option value="Litehouse Dressings">
                              Litehouse Dressings
                            </option>
                            <option value="Idaho Forrest Group">
                              Idaho Forrest Group
                            </option>
                            <option value="Olympic Steel">Olympic Steel</option>
                            <option value="BAE Systems">BAE Systems</option>
                            <option value="Infinity Signs">Infinity Signs</option>
                            <option value="River Club">River Club</option>
                            <option value="Daughters of the American Revolution (DAR)">
                              Daughters of the American Revolution (DAR)
                            </option>
                            <option value="USS ARKANSAS SSN 800 Commissioning Committee">USS ARKANSAS SSN 800 Commissioning Committee</option>
                            <option value="USS UTAH SSN 801 Commissioning Committee">USS UTAH SSN 801 Commissioning Committee</option>
                            <option value="USS OKLAHOMA SSN 802 Commissioning Committee">USS OKLAHOMA SSN 802 Commissioning Committee</option>
                            <option value="USS ARIZONA SSN 803 Commissioning Committee">USS ARIZONA SSN 803 Commissioning Committee</option>
                            <option value="USS District of Columbia SSBN 826 Commissioning Committee">USS District of Columbia SSBN 826 Commissioning Committee</option>
                            <option value="USS WISCONSIN SSN 827 Commissioning Committee">USS WISCONSIN SSN 827 Commissioning Committee</option>
                            <option value="NA">NA</option>
                            <option value="OTHER">OTHER</option>
                          </Form.Control>
                          {/* {errors.company_or_organization && (
                              <span style={styles.formError}>
                                Company or organization is required.
                              </span>
                            )} */}
                        </Col>
                      </Form.Row>
                    </Form.Group>
                  </Col>
                </Row>
  
                {watchObject.company_or_organization === "OTHER" && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Other (please specify)</Form.Label>
                        <Form.Control
                          className="formField"
                          type="text"
                          {...register("other_company_or_organization")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
  
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        className="formField"
                        type="text"
                        name="title"
                        {...register("title")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
  
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        If you are USS Idaho Commissioning Committee member, which
                        committee?
                      </Form.Label>
                      <Form.Row>
                        <Col sm={3}>
                          <Form.Control
                            as="select"
                            id="which_committee"
                            {...register("which_committee")}
                          >
                            <option value="">Select</option>
                            <option value="Planning">Planning</option>
                            <option value="Regional">Regional</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Finance">Finance</option>
                            <option value="Fundraising">Fundraising</option>
                            <option value="Membership">Membership</option>
                            <option value="BoD">BoD</option>
                          </Form.Control>
                        </Col>
                      </Form.Row>
                    </Form.Group>
                  </Col>
                </Row>
  
                <Row>
                  <Col>
                    <Alert variant="success" style={{marginBottom: 0}}>
                      <Form.Group>
                        <Form.Label style={{color: 'black'}}>
                          Are you, the requester, wanting a ticket for yourself?
                        </Form.Label>
                        <fieldset>
                          <input
                            className="radio"
                            type="radio"
                            label="Yes"
                            id={`ticket_for_self_yes`}
                            value="Yes"
                            {...register("ticket_for_self")}
                            onChange={() => {
                              setValue(
                                `person[0].first_name`,
                                watchObject.requester_first_name
                              )
                              setValue(
                                `person[0].last_name`,
                                watchObject.requester_last_name
                              )
                              setValue(
                                `person[0].email`,
                                watchObject.requester_email
                              )
                              setValue(
                                `person[0].phone`,
                                watchObject.requester_phone
                              )
                              setValue(
                                `person[0].street_address_1`,
                                watchObject.requester_street_address_1
                              )
                              setValue(
                                `person[0].street_address_2`,
                                watchObject.requester_street_address_2
                              )
                              setValue(
                                `person[0].street_address_3`,
                                watchObject.requester_street_address_3
                              )
                              setValue(
                                `person[0].city`,
                                watchObject.requester_city
                              )
                              setValue(
                                `person[0].state`,
                                watchObject.requester_state
                              )
                              setValue(
                                `person[0].zip`,
                                watchObject.requester_zip
                              )
                              setValue("ticket_for_self", "Yes")
                            }}
                          />
                          <label
                            className="radioLabel"
                            htmlFor={`ticket_for_self_yes`}
                            style={{color: 'black'}}
                          >
                            Yes
                          </label>
                          <input
                            className="radio"
                            type="radio"
                            id={`ticket_for_self_no`}
                            value="No"
                            {...register("ticket_for_self")}
                            onChange={() => {
                              setValue(`person[0].first_name`, "")
                              setValue(`person[0].last_name`, "")
                              setValue(`person[0].email`, "")
                              setValue(`person[0].phone`, "")
                              setValue(`person[0].street_address_1`, "")
                              setValue(`person[0].street_address_2`, "")
                              setValue(`person[0].street_address_3`, "")
                              setValue(`person[0].city`, "")
                              setValue(`person[0].state`, "")
                              setValue(`person[0].zip`, "")
    
                              setValue("ticket_for_self", "No")
                            }}
                          />
                          <label
                            className="radioLabel"
                            htmlFor={`ticket_for_self_no`}
                            style={{color: 'black'}}
                          >
                            No
                          </label>
                        </fieldset>
                      </Form.Group>
                    </Alert>
                  </Col>
                </Row>
  </div>
  
                {/* {fields.map((item, index) => { */}
                  {Array.from({ length: watchObject.number_of_tickets }).map((_, index) => {
  
                  return (
                    <div
                      style={{
                        marginBottom: "2rem",
                        border: "1px solid lightgray",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                      }}
                      key={index}
                    >
                      <Row>
                        <Col>
                          <h4>USS IDAHO Christening Ticket Request #{index + 1}</h4>
                        </Col>
                      </Row>
                      <Row class="fade-in">
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <Form.Label>
                              First Name
                              <Required />
                            </Form.Label>
                            {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                                className="formField"
                                type="text"
                                value={watchObject.requester_first_name}
                                readOnly
                                {...register(`person.${index}.first_name`)}
                              />
                            ) : (
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.first_name`)}
                              />
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <Form.Label>
                              Last Name
                              <Required />
                            </Form.Label>
                            {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                                className="formField"
                                type="text"
                                value={watchObject.requester_last_name}
                                readOnly
                                {...register(`person.${index}.last_name`)}
                              />
                            ) : (
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.last_name`)}
                              />
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <Form.Label>Email Address</Form.Label>
                            {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                                className="formField"
                                type="text"
                                value={watchObject.requester_email}
                                readOnly
                                {...register(`person.${index}.email`, {
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Enter a valid e-mail address",
                                  },
                                })}
                              />
                            ) : (
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.email`, {
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Enter a valid e-mail address",
                                  },
                                })}
                              />
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <Form.Label>
                              Phone Number (mobile preferred)
                              <Required />
                            </Form.Label>
                            {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                                className="formField"
                                type="text"
                                value={watchObject.requester_phone}
                                readOnly
                                {...register(`person.${index}.phone`, {
                                  pattern: {
                                    value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                    message: "Please enter a valid phone number",
                                  },
                                })}
                              />
                            ) : (
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.phone`, {
                                  pattern: {
                                    value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                    message: "Please enter a valid phone number",
                                  },
                                })}
                              />
                            )}
                            {/* {errors.phone && (
                            <span style={styles.formError}>
                              {errors.phone.message}
                            </span>
                          )} */}
                          </Form.Group>
                        </Col>
                      </Row>
                      
  
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Relationship to the requester</Form.Label>
                            <Form.Row>
                              <Col sm={3}>
                                <Form.Control
                                  as="select"
                                  id="relationship"
                                  {...register(`person.${index}.relationship`)}
                                >
                                  <option value="">Select</option>
                                  <option value="Self">Self</option>
                                  <option value="Colleague">Colleague</option>
                                  <option value="Colleague - Other">
                                    Colleague - Other
                                  </option>
                                  <option value="Spouse">Spouse</option>
                                  <option value="Partner">Partner</option>
                                  <option value="Family">Family</option>
                                  <option value="Friend">Friend</option>
                                  <option value="OTHER">OTHER</option>
                                </Form.Control>
                              </Col>
                            </Form.Row>
                          </Form.Group>
                        </Col>
                      </Row>
  
                      {/* <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Street Address 1</Form.Label>
                            {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                                className="formField"
                                type="text"
                                value={watchObject.requester_street_address_1}
                                readOnly
                                {...register(`person.${index}.street_address_1`)}
                              />
                            ) : (
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.street_address_1`)}
                              />
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Street Address 2</Form.Label>
                            {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                                className="formField"
                                type="text"
                                value={watchObject.requester_street_address_2}
                                readOnly
                                {...register(`person.${index}.street_address_2`)}
                              />
                            ) : (
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.street_address_2`)}
                              />
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Street Address 3</Form.Label>
                            {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                                className="formField"
                                type="text"
                                value={watchObject.requester_street_address_3}
                                readOnly
                                {...register(`person.${index}.street_address_3`)}
                              />
                            ) : (
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.street_address_3`)}
                              />
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col sm={12} md={4}>
                          <Form.Group>
                            <Form.Label>City</Form.Label>
                            {watchObject.ticket_for_self === "Yes" && index === 0 ? (
                              <Form.Control
                              as="select"
                              readOnly
                              value={watchObject.requester_city}
                              {...register(`person.${index}.city`)}
                            >
                              {CityList().cities.map(index => (
                                <option key={index} value={index}>
                                  {index}
                                </option>
                              ))}
                            </Form.Control>
                            ) : (
                              <Form.Control
                              as="select"
                              defaultValue="Select"
                              {...register(`person.${index}.city`)}
                            >
                              {CityList().cities.map(index => (
                                <option key={index} value={index}>
                                  {index}
                                </option>
                              ))}
                            </Form.Control>
                            )}
                            
                          </Form.Group>
                        </Col>
                        {watchObject.person[index] &&
                          watchObject.person[index].city ===
                            "OTHER (Specify)" && (
                            <Col>
                              <Form.Group>
                                <Form.Label>Other City</Form.Label>
                                {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                                className="formField"
                                type="text"
                                value={watchObject.requester_other_city}
                                readOnly
                                {...register(`person.${index}.other_city`)}
                              />
                            ) : (
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.other_city`)}
                              />
                            )}
                              </Form.Group>
                            </Col>
                          )}
                      </Row>
  
                      <Row>
                        <Col sm={12} md={4}>
                          <Form.Label>State</Form.Label>
                          {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                            as="select"
                            readOnly
                            value={watchObject.requester_state}
                            {...register(`person.${index}.state`)}
                          >
                            {StateList().states.map(index => (
                              <option key={index} value={index}>
                                {index}
                              </option>
                            ))}
                          </Form.Control>
                            ) : (
                              <Form.Control
                              as="select"
                              defaultValue="ID"
                              {...register(`person.${index}.state`)}
                            >
                              {StateList().states.map(index => (
                                <option key={index} value={index}>
                                  {index}
                                </option>
                              ))}
                            </Form.Control>
                            )}
                        </Col>
  
                        <Col sm={12} md={4}>
                          <Form.Group>
                            <Form.Label>Zip</Form.Label>
                            {watchObject.ticket_for_self === "Yes" &&
                            index === 0 ? (
                              <Form.Control
                                className="formField"
                                type="text"
                                value={watchObject.requester_zip}
                                readOnly
                                {...register(`person.${index}.zip`)}
                              />
                            ) : (
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.zip`)}
                              />
                            )}
                          </Form.Group>
                        </Col>
                      </Row> */}
  
                      <Row>
                        <Col sm={12} md={4}>
                          <Form.Label>Driver's license state of issue</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue="ID"
                            name={`person.${index}.drivers_license_state`}
                            {...register(`person.${index}.drivers_license_state`)}
                          >
                            {StateList().states.map(index => (
                              <option key={index} value={index}>
                                {index}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Driver's license number</Form.Label>
                            <Form.Control
                              className="formField"
                              type="text"
                              name={`person.${index}.drivers_license_number`}
                              {...register(
                                `person.${index}.drivers_license_number`
                              )}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Star Card?</Form.Label>
                            <fieldset>
                              <input
                                className="radio"
                                type="radio"
                                label="Yes"
                                id={`${index}.star_card_yes`}
                                value="Yes"
                                {...register(`person.${index}.star_card`)}
                              />
                              <label
                                className="radioLabel"
                                htmlFor={`${index}.star_card_yes`}
                              >
                                Yes
                              </label>
                              <input
                                className="radio"
                                type="radio"
                                id={`${index}.star_card_no`}
                                value="No"
                                {...register(`person.${index}.star_card`)}
                                style={{ marginLeft: "2rem" }}
                              />
                              <label
                                className="radioLabel"
                                htmlFor={`${index}.star_card_no`}
                              >
                                No
                              </label>
                            </fieldset>
                          </Form.Group>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col sm={12} md={4}>
                          <Form.Group>
                            <Form.Label>Date of birth</Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="mm/dd/yyyy"
                              name={`person.${index}.date_of_birth`}
                              {...register(`person.${index}.date_of_birth`)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <div style={{ marginBottom: "2rem",
                        border: "1px solid lightgray",
                        padding: "1rem",
                        borderRadius: "0.5rem",}}>
                        Please fill out this information if you have been requested to do so by the Commissioning Committee. Otherwise, please disregard.
                        <Form.Group>
                    <fieldset>
                      <input
                        className="radio"
                        type="checkbox"
                        inline
                        label="Yes"
                        id={`optional_info`}
                        name={`person.${index}.optional_info`}
                        value="Yes"
                        {...register(
                          `person.${index}.optional_info`
                        )}
                      />
                      <label
                        className="radioLabel"
                        htmlFor={`person.${index}.optional_info`}
                        style={{color: 'black', fontWeight: 'bold'}}
                      >
                        Enter Additional Optional Information
                      </label>
                    </fieldset>
                  </Form.Group>
                  {watchObject.person[index].optional_info === "Yes" && (
                    <Row className="fade-in">
                      <Col sm={12} md={3}>
                          <Form.Group>
                            <Form.Label>City of Birth</Form.Label>
                            <Form.Control
                              as="select"
                              defaultValue="Select"
                              {...register(`person.${index}.optional_city`)}
                            >
                              {CityList().cities.map(index => (
                                <option key={index} value={index}>
                                  {index}
                                </option>
                              ))}
                            </Form.Control>
                            <small>
                                Optional, but may be required for some events.
                              </small>
                          </Form.Group>
                        </Col>
                        {watchObject.person[index].optional_city ===
                            "OTHER (Specify)" && (
                            <Col>
                              <Form.Group>
                                <Form.Label>Other City</Form.Label>
                                <Form.Control
                                  className="formField"
                                  type="text"
                                  {...register(`person.${index}.optional_other_city`)}
                                />
                              </Form.Group>
                            </Col>
                          )}
                          <Col sm={12} md={3}>
                            <Form.Group>
                              <Form.Label>
                                Place of birth state
                                <br />
                              </Form.Label>
                              <Form.Control
                                as="select"
                                defaultValue="ID"
                                name={`person.${index}.state_of_birth`}
                                {...register(`person.${index}.state_of_birth`)}
                              >
                                {StateList().states.map(index => (
                                  <option key={index} value={index}>
                                    {index}
                                  </option>
                                ))}
                              </Form.Control>
                              <small>
                                Optional, but may be required for some events.
                              </small>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={3}>
                            <Form.Group>
                              <Form.Label>
                                Place of birth country
                                <br />
                              </Form.Label>
                              <Form.Control
                                type="text"
                                defaultValue={"United States"}
                                placeholder="United States"
                                name={`person.${index}.country_of_birth`}
                                {...register(`person.${index}.country_of_birth`)}
                                style={{ marginBottom: 0 }}
                              />
                              <small>
                                Optional, but may be required for some events.
                              </small>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={3}>
                            <Form.Group>
                              <Form.Label>
                                Last Four Digits of Social Security Number
                                <br />
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`person.${index}.ssn`}
                                {...register(`person.${index}.ssn`)}
                                style={{ marginBottom: 0 }}
                              />
                              <small>
                                Optional, but may be required for some events.
                              </small>
                            </Form.Group>
                          </Col>
                    </Row>
                  )}
                      </div>
                      <Row>
                      
                        <Col>
                          <Form.Group>
                            <Form.Label>US citizen?</Form.Label>
                            <fieldset>
                              <input
                                className="radio"
                                type="radio"
                                label="Yes"
                                name={`person.${index}.us_citizen`}
                                id={`${index}.us_citizen_yes`}
                                value="Yes"
                                {...register(`person.${index}.us_citizen`)}
                              />
                              <label
                                className="radioLabel"
                                htmlFor={`${index}.us_citizen_yes`}
                              >
                                Yes
                              </label>
                              <input
                                className="radio"
                                type="radio"
                                name={`person.${index}.us_citizen`}
                                id={`${index}.us_citizen_no`}
                                value="No"
                                {...register(`person.${index}.us_citizen`)}
                                style={{ marginLeft: "2rem" }}
                              />
                              <label
                                className="radioLabel"
                                htmlFor={`${index}.us_citizen_no`}
                              >
                                No
                              </label>
                            </fieldset>
                          </Form.Group>
                        </Col>
                        {watchObject.person[index] &&
                          watchObject.person[index].us_citizen === "No" && (
                            <Col sm={12} md={4}>
                              <Form.Group>
                                <Form.Label>
                                  If not a US citizen, country of citizenship
                                  <br />
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name={`person.${index}.country_of_citizenship`}
                                  {...register(
                                    `person.${index}.country_of_citizenship`
                                  )}
                                />
                              </Form.Group>
                            </Col>
                          )}
                      </Row>
  
                      <Row>
                        <Col sm={12} md={4}>
                          <Form.Group>
                            <Form.Label>Do you have a US passport?</Form.Label>
                            <fieldset>
                              <input
                                className="radio"
                                type="radio"
                                label="Yes"
                                name={`person.${index}.us_passport`}
                                id={`${index}.us_passport_yes`}
                                value="Yes"
                                {...register(`person.${index}.us_passport`)}
                              />
                              <label
                                className="radioLabel"
                                htmlFor={`${index}.us_passport_yes`}
                              >
                                Yes
                              </label>
                              <input
                                className="radio"
                                type="radio"
                                name={`person.${index}.us_passport`}
                                id={`${index}.us_passport_no`}
                                value="No"
                                {...register(`person.${index}.us_passport`)}
                                style={{ marginLeft: "2rem" }}
                              />
                              <label
                                className="radioLabel"
                                htmlFor={`${index}.us_passport_no`}
                              >
                                No
                              </label>
                            </fieldset>
                          </Form.Group>
                        </Col>
                        {watchObject.person[index] &&
                        watchObject.person[index].us_passport === "Yes" ? (
                          <Col className="fade-in">
                            <Form.Group>
                              <Form.Label>Passport number</Form.Label>
                              <Form.Control
                                type="text"
                                name={`person.${index}.passport_number`}
                                {...register(`person.${index}.passport_number`)}
                              />
                            </Form.Group>
                          </Col>
                        ) : watchObject.person[index] &&
                          watchObject.person[index].us_passport === "No" ? (
                            <>
                              <Col className="fade-in">
                              <Form.Group>
                                <Form.Label>
                                  Other country passport
                                  <br />
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name={`person.${index}.other_country_passport`}
                                  {...register(
                                    `person.${index}.other_country_passport`
                                  )}
                                />
                              </Form.Group>
                            </Col>
                            <Col className="fade-in">
                              <Form.Group>
                                <Form.Label>
                                  Other country passport number
                                  <br />
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name={`person.${index}.other_country_passport_number`}
                                  {...register(
                                    `person.${index}.other_country_passport_number`
                                  )}
                                />
                              </Form.Group>
                            </Col>
                            </>
                        ) : null}
                      </Row>
  
                      <Row>
                        <Col sm={12} md={3}>
                          <Form.Group>
                            <Form.Label>Veteran?</Form.Label>
                            <fieldset>
                              <input
                                className="radio"
                                type="radio"
                                label="Yes"
                                name={`person.${index}.veteran`}
                                id={`${index}.veteran_yes`}
                                value="Yes"
                                {...register(`person.${index}.veteran`)}
                              />
                              <label
                                className="radioLabel"
                                htmlFor={`${index}.veteran_yes`}
                              >
                                Yes
                              </label>
                              <input
                                className="radio"
                                type="radio"
                                name={`person.${index}.veteran`}
                                id={`${index}.veteran_yes`}
                                value="No"
                                {...register(`person.${index}.veteran`)}
                                style={{ marginLeft: "2rem" }}
                              />
                              <label
                                className="radioLabel"
                                htmlFor={`${index}.veteran_yes`}
                              >
                                No
                              </label>
                            </fieldset>
                          </Form.Group>
                        </Col>
                        {watchObject.person[index] &&
                          watchObject.person[index].veteran === "Yes" && (
                            <>
                              <Col className="fade-in" sm={12} md={3}>
                                <Form.Group>
                                  <Form.Label>Branch</Form.Label>
                                  <Form.Row>
                                    <Col>
                                      <Form.Control
                                        as="select"
                                        id="branch"
                                        {...register(`person.${index}.branch`)}
                                      >
                                        <option value="">Select</option>
                                        <option value="Navy">Navy</option>
                                        <option value="Marine Corps">
                                          Marine Corps
                                        </option>
                                        <option value="Army">Army</option>
                                        <option value="Air Force">
                                          Air Force
                                        </option>
                                        <option value="Coast Guard">
                                          Coast Guard
                                        </option>
                                        <option value="National Guard">
                                          National Guard
                                        </option>
                                        <option value="Merchant Marine">
                                          Merchant Marine
                                        </option>
                                      </Form.Control>
                                    </Col>
                                  </Form.Row>
                                </Form.Group>
                              </Col>
                              <Col className="fade-in" sm={12} md={3}>
                                <Form.Group>
                                  <Form.Label>Status</Form.Label>
                                  <Form.Row>
                                    <Col>
                                      <Form.Control
                                        as="select"
                                        id="status"
                                        {...register(`person.${index}.status`)}
                                      >
                                        <option value="">Select</option>
                                        <option value="Active Duty">
                                          Active Duty
                                        </option>
                                        <option value="Reserve">Reserve</option>
                                        <option value="National Guard">
                                          National Guard
                                        </option>
                                        <option value="Retired (w/ DoD ID)">
                                          Retired (w/ DoD ID)
                                        </option>
                                        <option value="Veteran (no DoD ID)">
                                          Veteran (no DoD ID)
                                        </option>
                                      </Form.Control>
                                    </Col>
                                  </Form.Row>
                                </Form.Group>
                              </Col>
                              <Col className="fade-in" sm={12} md={3}>
                                <Form.Group>
                                  <Form.Label>Rank</Form.Label>
                                  <Form.Row>
                                    <Col>
                                      <Form.Control
                                        as="select"
                                        id="rank"
                                        {...register(`person.${index}.rank`)}
                                      >
                                        <option value="">Select</option>
                                        <option value="ADM">ADM</option>
                                        <option value="VADM">VADM</option>
                                        <option value="RADM">RADM</option>
                                        <option value="RDML">RDML</option>
                                        <option value="CAPT">CAPT</option>
                                        <option value="CDR">CDR</option>
                                        <option value="LCDR">LCDR</option>
                                        <option value="LT">LT</option>
                                        <option value="LTJG">LTJG</option>
                                        <option value="ENS">ENS</option>
                                        <option value="CWO5">CWO5</option>
                                        <option value="CWO4">CWO4</option>
                                        <option value="CWO3">CWO3</option>
                                        <option value="CWO2">CWO2</option>
                                        <option value="WO1">WO1</option>
                                        <option value="MCPON">MCPON</option>
                                        <option value="MPCOCG">MPCOCG</option>
                                        <option value="FORCM">FORCM</option>
                                        <option value="FLTCM">FLTCM</option>
                                        <option value="MCPOC">MCPOC</option>
                                        <option value="MCPO">MCPO</option>
                                        <option value="SCPO">SCPO</option>
                                        <option value="CPO">CPO</option>
                                        <option value="PO1">PO1</option>
                                        <option value="PO2">PO2</option>
                                        <option value="PO3">PO3</option>
                                        <option value="SN">SN</option>
                                        <option value="SA">SA</option>
                                        <option value="SR">SR</option>
                                        <option value="Gen">Gen</option>
                                        <option value="LtGen">LtGen</option>
                                        <option value="MajGen">MajGen</option>
                                        <option value="BGen">BGen</option>
                                        <option value="Col">Col</option>
                                        <option value="LtCol">LtCol</option>
                                        <option value="Maj">Maj</option>
                                        <option value="Capt">Capt</option>
                                        <option value="1stLt">1stLt</option>
                                        <option value="2ndLt">2ndLt</option>
                                        <option value="CW5">CW5</option>
                                        <option value="CW4">CW4</option>
                                        <option value="CW3">CW3</option>
                                        <option value="CW2">CW2</option>
                                        <option value="WO1">WO1</option>
                                        <option value="SgtMajMarCor">
                                          SgtMajMarCor
                                        </option>
                                        <option value="SgtMaj">SgtMaj</option>
                                        <option value="MGySgt">MGySgt</option>
                                        <option value="1stSgt">1stSgt</option>
                                        <option value="MSgt">MSgt</option>
                                        <option value="GySgt">GySgt</option>
                                        <option value="SSgt">SSgt</option>
                                        <option value="Sgt">Sgt</option>
                                        <option value="Cpl">Cpl</option>
                                        <option value="LCpl">LCpl</option>
                                        <option value="PFC">PFC</option>
                                        <option value="Pvt">Pvt</option>
                                        <option value="GEN">GEN</option>
                                        <option value="LTG">LTG</option>
                                        <option value="MG">MG</option>
                                        <option value="BG">BG</option>
                                        <option value="COL">COL</option>
                                        <option value="LTC">LTC</option>
                                        <option value="MAJ">MAJ</option>
                                        <option value="CPT">CPT</option>
                                        <option value="1LT">1LT</option>
                                        <option value="2LT">2LT</option>
                                        <option value="CW5">CW5</option>
                                        <option value="CW4">CW4</option>
                                        <option value="CW3">CW3</option>
                                        <option value="CW2">CW2</option>
                                        <option value="WO1">WO1</option>
                                        <option value="SMA">SMA</option>
                                        <option value="CSM">CSM</option>
                                        <option value="SGM">SGM</option>
                                        <option value="1SG">1SG</option>
                                        <option value="MSG">MSG</option>
                                        <option value="SFC">SFC</option>
                                        <option value="SSG">SSG</option>
                                        <option value="SGT">SGT</option>
                                        <option value="CPL">CPL</option>
                                        <option value="SPC">SPC</option>
                                        <option value="PFC">PFC</option>
                                        <option value="PV2">PV2</option>
                                        <option value="PVT">PVT</option>
                                        <option value="Gen">Gen</option>
                                        <option value="Lt Gen">Lt Gen</option>
                                        <option value="Maj Gen">Maj Gen</option>
                                        <option value="Brig Gen">Brig Gen</option>
                                        <option value="Col">Col</option>
                                        <option value="Lt Col">Lt Col</option>
                                        <option value="Maj">Maj</option>
                                        <option value="Capt">Capt</option>
                                        <option value="1st Lt">1st Lt</option>
                                        <option value="2d Lt">2d Lt</option>
                                        <option value="CMSAF">CMSAF</option>
                                        <option value="CCM">CCM</option>
                                        <option value="CMSgt">CMSgt</option>
                                        <option value="SMSgt">SMSgt</option>
                                        <option value="MSgt">MSgt</option>
                                        <option value="TSgt">TSgt</option>
                                        <option value="SSgt">SSgt</option>
                                        <option value="SrA">SrA</option>
                                        <option value="A1C">A1C</option>
                                        <option value="Amn">Amn</option>
                                        <option value="AB">AB</option>
                                      </Form.Control>
                                    </Col>
                                  </Form.Row>
                                </Form.Group>
                              </Col>
                            </>
                          )}
                      </Row>
  
                      {watchObject.person[index].status === "Active Duty" ||
                      watchObject.person[index].status === "Reserve" ||
                      watchObject.person[index].status === "National Guard" ||
                      watchObject.person[index].status ===
                        "Retired (w/ DoD ID)" ? (
                        <Row>
                          <Col className="fade-in">
                            <Form.Group>
                              <Form.Label>DoD ID Number</Form.Label>
                              <Form.Control
                                type="text"
                                name={`person.${index}.dod_id_number`}
                                {...register(`person.${index}.dod_id_number`)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      ) : null}
  
                      <Row>
                        <Col className="fade-in">
                          <Form.Group>
                            <Form.Label>Comments</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name={`person.${index}.comments`}
                              {...register(`person.${index}.comments`)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col sm={12} md={3}>
                          <Form.Group>
                            <Form.Label>
                              Veteran Service ORG
                              <br />
                              <small>(Check all that apply.)</small>
                            </Form.Label>
                            <div>
                              <Form.Check
                                type="checkbox"
                                label="SUBVET (USSVI)"
                                value="SUBVET (USSVI)"
                                {...register(
                                  `person.${index}.veteran_service_org`
                                )}
                              />
                              <Form.Check
                                type="checkbox"
                                label="American Legion"
                                value="American Legion"
                                {...register(
                                  `person.${index}.veteran_service_org`
                                )}
                              />
                              <Form.Check
                                type="checkbox"
                                label="Veterans of Foreign Wars"
                                value="Veterans of Foreign Wars"
                                {...register(
                                  `person.${index}.veteran_service_org`
                                )}
                              />
                              <Form.Check
                                type="checkbox"
                                label="OTHER (Specify)"
                                value="OTHER (Specify)"
                                {...register(
                                  `person.${index}.veteran_service_org`
                                )}
                              />
                              <Form.Check
                                type="checkbox"
                                label="NONE"
                                value="NONE"
                                {...register(
                                  `person.${index}.veteran_service_org`
                                )}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        {watchObject.person[index].veteran_service_org &&
                        watchObject.person[index].veteran_service_org.includes(
                          "OTHER (Specify)"
                        ) ? (
                          <Col className="fade-in">
                            <Form.Group>
                              <Form.Label>Other Veteran Service ORG</Form.Label>
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(
                                  `person.${index}.other_veteran_service_org`
                                )}
                              />
                            </Form.Group>
                          </Col>
                        ) : watchObject.person[index].veteran_service_org &&
                          watchObject.person[index].veteran_service_org.includes(
                            "SUBVET (USSVI)"
                          ) ? (
                          <Col className="fade-in" sm={12} md="4">
                            <Form.Group>
                              <Form.Label>Base</Form.Label>
                              <Form.Row>
                                <Col>
                                  <Form.Control
                                    as="select"
                                    id="status"
                                    {...register(`person.${index}.subvet_base`)}
                                  >
                                    <option value="">Select</option>
                                    <option value="Hawkbill">Hawkbill</option>
                                    <option value="Boise">Boise</option>
                                    <option value="Farragut">Farragut</option>
                                    <option value="Trout">Trout</option>
                                    <option value="Requin">Requin</option>
                                    <option value="OTHER (Specify)">
                                      OTHER (Specify)
                                    </option>
                                  </Form.Control>
                                </Col>
                              </Form.Row>
                            </Form.Group>
                          </Col>
                        ) : null}
                        {watchObject.person[index].subvet_base &&
                        watchObject.person[index].subvet_base ===
                          "OTHER (Specify)" ? (
                          <Col className="fade-in">
                            <Form.Group>
                              <Form.Label>Other base</Form.Label>
                              <Form.Control
                                className="formField"
                                type="text"
                                {...register(`person.${index}.other_base`)}
                              />
                            </Form.Group>
                          </Col>
                        ) : null}
                      </Row>
  
  
                      
  
                      {/* <Row className="text-right">
                        <Col>
                          {watchObject.person && watchObject.person.length > 1 && (
                            <Button
                              variant="danger"
                              btn
                              btn-sm
                              onClick={() => remove(index)}
                            >
                              <img
                                src={MinusSymbol}
                                alt="Add another person"
                                style={{ marginBottom: "3px" }}
                              />{" "}
                              Remove This Person
                            </Button>
                          )}
                        </Col>
                      </Row> */}
                    </div>
                  )
                })}
              </div>
  
              <Row className="mb-4">
                {/* <Col>
                  <Button
                    variant="success"
                    btn
                    btn-sm
                    onClick={() =>
                      append({
                        email: "",
                        first_name: "",
                        last_name: "",
                        phone: "",
                        relationship: "",
                        street_address_1: "",
                        street_address_2: "",
                        street_address_3: "",
                        city: "",
                        state: "",
                        zip: "",
                        other_city: "",
                        drivers_license_state: "",
                        drivers_license_number: "",
                        star_card: "",
                        date_of_birth: "",
                        city_of_birth: "",
                        state_of_birth: "",
                        country_of_birth: "",
                        us_citizen: "",
                        country_of_citizenship: "",
                        other_country_of_citizenship: "",
                        us_passport: "",
                        passport_number: "",
                        other_country_passport: "",
                        veteran: "",
                        branch: "",
                        status: "",
                        rank: "",
                        dod_id_number: "",
                        comments: "",
                        veteran_service_org: [],
                        other_veteran_service_org: "",
                        subvet_base: "",
                      })
                    }
                    className="mr-4"
                  >
                    <img
                      src={PlusSymbol}
                      alt="Add another person"
                      style={{ marginBottom: "3px" }}
                    />{" "}
                    Add Another Person
                  </Button>
                </Col> */}
                </Row>
  
                      <Row>
                        <Col>
                          <Alert variant="success">
                            <Form.Group style={{marginBottom: 0}}>
                              <Form.Label style={{color: 'black'}}>
                                Would you like to request travel booking assistance from Uniglobe Travel for any of the above?
                              </Form.Label>
                              <fieldset>
                                <input
                                  className="radio"
                                  type="radio"
                                  label="Yes"
                                  id={`travel_assistance_yes`}
                                  name={`travel_assistance_yes`}
                                  value="Yes"
                                  {...register(`travel_assistance`)}
                                />
                                <label
                                  className="radioLabel"
                                  htmlFor={`travel_assistance_yes`}
                                  style={{color: 'black'}}
                                >
                                  Yes
                                </label>
                                <input
                                  className="radio"
                                  type="radio"
                                  id={`travel_assistance_no`}
                                  name={`travel_assistance_no`}
                                  value="No"
                                  {...register(`travel_assistance`)}
                                  style={{ marginLeft: "2rem" }}
                                />
                                <label
                                  className="radioLabel"
                                  htmlFor={`travel_assistance_no`}
                                  style={{color: 'black'}}
                                >
                                  No
                                </label>
                              </fieldset>
                            </Form.Group>
                            {watchObject.travel_assistance === "Yes" && (
                              <p>Upon submitting this form with the SUBMIT button below, you will then be presented with an additional travel options form for each of your ticket requests.</p>
                            )}
                          </Alert>
                        </Col>
                      </Row>
                <Row>
                  <Button
                    type="submit"
                    variant="primary"
                    form="new-request-form"
                    disabled={disableSubmit}
                    className="submitButton teamButton"
                  >
                    {succeeded
                      ? "Message submitted"
                      : processing
                      ? "SUBMITTING..."
                      : "SUBMIT"}
                  </Button>
                </Row>
              
            </form>
         </div>) }
        </>
      )}
    </>
  )
}
